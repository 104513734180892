import React from 'react';

import { graphql } from 'gatsby';
import classNames from 'classnames';
import Container from '../layouts/container';

import {
  useBreakpoints, breakpointMdOrLarger, formatDateWithMonth, formatYear,
} from '../utils';

import { Download } from '../assets/svg';

import styles from './cv.module.scss';

import {
  summary,
  employmentHistory,
  skills,
  tools,
  education,
  qualifications,
} from '../../content/pages/cv.json';
import SVGIconButton from '../components/svg-icon-button';


const SectionTitle = ({ children }) => <h2 className={styles.sectionTitle}>{ children }</h2>;

const parseDates = ({ startDate, endDate, ...rest }) => ({
  startDate: startDate ? new Date(startDate) : null,
  endDate: endDate ? new Date(endDate) : 'Present',
  ...rest,
});
const formatDates = (formatFn) => ({ startDate, endDate, ...rest }) => ({
  startDate: formatFn(startDate),
  endDate: formatFn(endDate),
  ...rest,
});

const employmentHistoryListItems = employmentHistory
  .map(parseDates)
  .sort((a, b) => (a.startDate.valueOf() > b.startDate.valueOf() ? -1 : 1))
  .map(formatDates(formatDateWithMonth))
  .map(
    ({
      startDate,
      endDate,
      employer,
      post,
      description,
    }, i) => (
      <li key={i}>
        <p className={styles.employmentDetails}>
          {post}
          ,
          {' '}
          {employer}
          {' '}
          &mdash;
          {' '}
          {startDate}
          &ndash;
          {endDate}
        </p>
        {description.map((paragraph, i) => <p key={i} className={styles.paragraph}>{paragraph}</p>)}
      </li>
    ),
  );

const educationListItems = education
  .map(parseDates)
  .map(formatDates(formatYear('')))
  .map(({
    institution,
    qualification,
    startDate,
    endDate,
  }) => (
    <li className={styles.listItem}>
      {qualification}
      {' '}
      &mdash;
      {' '}
      {institution}
      ,
      {' '}
      {startDate}
      {startDate && '–'}
      {endDate}
    </li>
  ));

const CV = ({ data }) => {
  const breakpoint = useBreakpoints();
  const isLargerScreen = breakpointMdOrLarger(breakpoint);

  return (
    <Container>
      <section className={styles.container}>
        <section className={styles.pageHeader}>
          <h1 className={styles.pageTitle}>Curriculum Vitæ</h1>
          {isLargerScreen && <SVGIconButton to={data.allFile.edges[0].node.publicURL} svgIcon={Download} label="Download PDF Version" variant="brandPrimary" />}
        </section>
        <p className={styles.summary}>{ summary }</p>
        <section className={styles.section}>
          <SectionTitle>Relevant Experience</SectionTitle>
          <ul className={styles.employmentHistoryList}>
            { employmentHistoryListItems }
          </ul>
        </section>
        <section className={styles.section}>
          <SectionTitle>Skills</SectionTitle>
          <p className={classNames(styles.paragraph, styles.noMargin)}>{ skills.join(', ') }</p>
        </section>
        <section className={styles.section}>
          <SectionTitle>Tools</SectionTitle>
          <p className={classNames(styles.paragraph, styles.noMargin)}>{ tools.join(', ') }</p>
        </section>
        <section className={styles.section}>
          <SectionTitle>Education</SectionTitle>
          <ul className={styles.list}>
            { educationListItems }
          </ul>
        </section>
        <section className={styles.section}>
          <SectionTitle>Qualifications</SectionTitle>
          <ul className={styles.list}>
            { qualifications.map((qualification) => <p className={styles.listItem}>{qualification}</p>) }
          </ul>
        </section>
      </section>
    </Container>
  );
};

export const query = graphql`
  {
    allFile( filter: { name: { eq: "rosemaryfenton-cv" } } ) {
      edges {
        node {
          publicURL,
          name
        }
      }
    }
  }
`;

export default CV;
